/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `dark` - Dark background * `light` - Light background * `light_orange` - Light orange background * `primary` - Primary background
 * @export
 * @enum {string}
 */

export const ThemeColorEnum = {
    Dark: 'dark',
    Light: 'light',
    LightOrange: 'light_orange',
    Primary: 'primary'
} as const;

export type ThemeColorEnum = typeof ThemeColorEnum[keyof typeof ThemeColorEnum];



