/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `hero` - hero * `logo_list` - logo_list * `service_cards` - service_cards * `product_listing` - product_listing * `subscription_listing` - subscription_listing * `interactive_tabs` - interactive_tabs * `pitchtext` - pitchtext * `testimonials` - testimonials * `accordion` - accordion * `features_list` - features_list * `comparison_table` - comparison_table * `zig_zag_features` - zig_zag_features * `text_with_image` - text_with_image * `statistics` - statistics * `newsletter` - newsletter * `news_mentions_list` - news_mentions_list * `vertical_video_gallery` - vertical_video_gallery * `open_positions` - open_positions * `media_gallery` - media_gallery * `features_grid` - features_grid * `employees_grid` - employees_grid * `fade_in_text` - fade_in_text * `matura_exam_bulk_download` - matura_exam_bulk_download * `downloadables` - downloadables * `step_cards` - step_cards
 * @export
 * @enum {string}
 */

export const LandingPageBlockSharedTypeEnum = {
    Hero: 'hero',
    LogoList: 'logo_list',
    ServiceCards: 'service_cards',
    ProductListing: 'product_listing',
    SubscriptionListing: 'subscription_listing',
    InteractiveTabs: 'interactive_tabs',
    Pitchtext: 'pitchtext',
    Testimonials: 'testimonials',
    Accordion: 'accordion',
    FeaturesList: 'features_list',
    ComparisonTable: 'comparison_table',
    ZigZagFeatures: 'zig_zag_features',
    TextWithImage: 'text_with_image',
    Statistics: 'statistics',
    Newsletter: 'newsletter',
    NewsMentionsList: 'news_mentions_list',
    VerticalVideoGallery: 'vertical_video_gallery',
    OpenPositions: 'open_positions',
    MediaGallery: 'media_gallery',
    FeaturesGrid: 'features_grid',
    EmployeesGrid: 'employees_grid',
    FadeInText: 'fade_in_text',
    MaturaExamBulkDownload: 'matura_exam_bulk_download',
    Downloadables: 'downloadables',
    StepCards: 'step_cards'
} as const;

export type LandingPageBlockSharedTypeEnum = typeof LandingPageBlockSharedTypeEnum[keyof typeof LandingPageBlockSharedTypeEnum];



