/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AllPagePaths } from '../models';
// @ts-ignore
import type { LayoutSettings } from '../models';
// @ts-ignore
import type { LoginRequiredPage } from '../models';
// @ts-ignore
import type { MaturaExamFilesBySubject } from '../models';
// @ts-ignore
import type { PageByPath } from '../models';
// @ts-ignore
import type { PaginatedLayoutSettingsList } from '../models';
// @ts-ignore
import type { PaginatedProductList } from '../models';
// @ts-ignore
import type { PaginatedSocialMediaSettingsList } from '../models';
// @ts-ignore
import type { PatchedProductRequest } from '../models';
// @ts-ignore
import type { Product } from '../models';
// @ts-ignore
import type { ProductRequest } from '../models';
// @ts-ignore
import type { SocialMediaSettings } from '../models';
/**
 * WebshopApi - axios parameter creator
 * @export
 */
export const WebshopApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateZipWithFiles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/webshop/api/matura-exam-bulk-download/generate-zip/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPagePaths: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/webshop/api/page-by-path/all-page-paths/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get exam files by subject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExamFilesBySubject: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/webshop/api/matura-exam-bulk-download/exam-files-by-subject/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Layout Settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayoutSettings: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLayoutSettings', 'id', id)
            const localVarPath = `/wt/webshop/api/layout-settings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} htmlPath 
         * @param {string} [host] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageByPath: async (htmlPath: string, host?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'htmlPath' is not null or undefined
            assertParamExists('getPageByPath', 'htmlPath', htmlPath)
            const localVarPath = `/wt/webshop/api/page-by-path/page-by-path/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (host !== undefined) {
                localVarQueryParameter['host'] = host;
            }

            if (htmlPath !== undefined) {
                localVarQueryParameter['html_path'] = htmlPath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageByPathPreview: async (contentType?: string, inPreviewPanel?: boolean, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/webshop/api/page-by-path-preview/preview/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }

            if (inPreviewPanel !== undefined) {
                localVarQueryParameter['in_preview_panel'] = inPreviewPanel;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProduct', 'id', id)
            const localVarPath = `/wt/webshop/api/product/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Social media settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSocailMediaSettings: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSocailMediaSettings', 'id', id)
            const localVarPath = `/wt/webshop/api/social-media-settings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiLayoutSettingsList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/webshop/api/layout-settings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductRequest} productRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiProductCreate: async (productRequest: ProductRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productRequest' is not null or undefined
            assertParamExists('webshopApiProductCreate', 'productRequest', productRequest)
            const localVarPath = `/wt/webshop/api/product/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiProductDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webshopApiProductDestroy', 'id', id)
            const localVarPath = `/wt/webshop/api/product/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiProductList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/webshop/api/product/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Product.
         * @param {PatchedProductRequest} [patchedProductRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiProductPartialUpdate: async (id: number, patchedProductRequest?: PatchedProductRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webshopApiProductPartialUpdate', 'id', id)
            const localVarPath = `/wt/webshop/api/product/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Product.
         * @param {ProductRequest} productRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiProductUpdate: async (id: number, productRequest: ProductRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webshopApiProductUpdate', 'id', id)
            // verify required parameter 'productRequest' is not null or undefined
            assertParamExists('webshopApiProductUpdate', 'productRequest', productRequest)
            const localVarPath = `/wt/webshop/api/product/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiSocialMediaSettingsList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/webshop/api/social-media-settings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebshopApi - functional programming interface
 * @export
 */
export const WebshopApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebshopApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateZipWithFiles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaturaExamFilesBySubject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateZipWithFiles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.generateZipWithFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPagePaths(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllPagePaths>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPagePaths(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.getAllPagePaths']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get exam files by subject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExamFilesBySubject(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaturaExamFilesBySubject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExamFilesBySubject(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.getExamFilesBySubject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Layout Settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLayoutSettings(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayoutSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLayoutSettings(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.getLayoutSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} htmlPath 
         * @param {string} [host] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPageByPath(htmlPath: string, host?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageByPath>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPageByPath(htmlPath, host, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.getPageByPath']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPageByPathPreview(contentType?: string, inPreviewPanel?: boolean, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageByPath>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPageByPathPreview(contentType, inPreviewPanel, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.getPageByPathPreview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProduct(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProduct(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.getProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Social media settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSocailMediaSettings(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialMediaSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSocailMediaSettings(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.getSocailMediaSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webshopApiLayoutSettingsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedLayoutSettingsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webshopApiLayoutSettingsList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.webshopApiLayoutSettingsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProductRequest} productRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webshopApiProductCreate(productRequest: ProductRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webshopApiProductCreate(productRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.webshopApiProductCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webshopApiProductDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webshopApiProductDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.webshopApiProductDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webshopApiProductList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webshopApiProductList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.webshopApiProductList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Product.
         * @param {PatchedProductRequest} [patchedProductRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webshopApiProductPartialUpdate(id: number, patchedProductRequest?: PatchedProductRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webshopApiProductPartialUpdate(id, patchedProductRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.webshopApiProductPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Product.
         * @param {ProductRequest} productRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webshopApiProductUpdate(id: number, productRequest: ProductRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webshopApiProductUpdate(id, productRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.webshopApiProductUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webshopApiSocialMediaSettingsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSocialMediaSettingsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webshopApiSocialMediaSettingsList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebshopApi.webshopApiSocialMediaSettingsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WebshopApi - factory interface
 * @export
 */
export const WebshopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebshopApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateZipWithFiles(options?: RawAxiosRequestConfig): AxiosPromise<MaturaExamFilesBySubject> {
            return localVarFp.generateZipWithFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPagePaths(options?: RawAxiosRequestConfig): AxiosPromise<AllPagePaths> {
            return localVarFp.getAllPagePaths(options).then((request) => request(axios, basePath));
        },
        /**
         * Get exam files by subject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExamFilesBySubject(options?: RawAxiosRequestConfig): AxiosPromise<MaturaExamFilesBySubject> {
            return localVarFp.getExamFilesBySubject(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebshopApiGetLayoutSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayoutSettings(requestParameters: WebshopApiGetLayoutSettingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<LayoutSettings> {
            return localVarFp.getLayoutSettings(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebshopApiGetPageByPathRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageByPath(requestParameters: WebshopApiGetPageByPathRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageByPath> {
            return localVarFp.getPageByPath(requestParameters.htmlPath, requestParameters.host, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebshopApiGetPageByPathPreviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageByPathPreview(requestParameters: WebshopApiGetPageByPathPreviewRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageByPath> {
            return localVarFp.getPageByPathPreview(requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebshopApiGetProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct(requestParameters: WebshopApiGetProductRequest, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.getProduct(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebshopApiGetSocailMediaSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSocailMediaSettings(requestParameters: WebshopApiGetSocailMediaSettingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<SocialMediaSettings> {
            return localVarFp.getSocailMediaSettings(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebshopApiWebshopApiLayoutSettingsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiLayoutSettingsList(requestParameters: WebshopApiWebshopApiLayoutSettingsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedLayoutSettingsList> {
            return localVarFp.webshopApiLayoutSettingsList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebshopApiWebshopApiProductCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiProductCreate(requestParameters: WebshopApiWebshopApiProductCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.webshopApiProductCreate(requestParameters.productRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebshopApiWebshopApiProductDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiProductDestroy(requestParameters: WebshopApiWebshopApiProductDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.webshopApiProductDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebshopApiWebshopApiProductListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiProductList(requestParameters: WebshopApiWebshopApiProductListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProductList> {
            return localVarFp.webshopApiProductList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebshopApiWebshopApiProductPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiProductPartialUpdate(requestParameters: WebshopApiWebshopApiProductPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.webshopApiProductPartialUpdate(requestParameters.id, requestParameters.patchedProductRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebshopApiWebshopApiProductUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiProductUpdate(requestParameters: WebshopApiWebshopApiProductUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.webshopApiProductUpdate(requestParameters.id, requestParameters.productRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebshopApiWebshopApiSocialMediaSettingsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webshopApiSocialMediaSettingsList(requestParameters: WebshopApiWebshopApiSocialMediaSettingsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedSocialMediaSettingsList> {
            return localVarFp.webshopApiSocialMediaSettingsList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getLayoutSettings operation in WebshopApi.
 * @export
 * @interface WebshopApiGetLayoutSettingsRequest
 */
export interface WebshopApiGetLayoutSettingsRequest {
    /**
     * A unique integer value identifying this Layout Settings.
     * @type {number}
     * @memberof WebshopApiGetLayoutSettings
     */
    readonly id: number
}

/**
 * Request parameters for getPageByPath operation in WebshopApi.
 * @export
 * @interface WebshopApiGetPageByPathRequest
 */
export interface WebshopApiGetPageByPathRequest {
    /**
     * 
     * @type {string}
     * @memberof WebshopApiGetPageByPath
     */
    readonly htmlPath: string

    /**
     * 
     * @type {string}
     * @memberof WebshopApiGetPageByPath
     */
    readonly host?: string
}

/**
 * Request parameters for getPageByPathPreview operation in WebshopApi.
 * @export
 * @interface WebshopApiGetPageByPathPreviewRequest
 */
export interface WebshopApiGetPageByPathPreviewRequest {
    /**
     * 
     * @type {string}
     * @memberof WebshopApiGetPageByPathPreview
     */
    readonly contentType?: string

    /**
     * 
     * @type {boolean}
     * @memberof WebshopApiGetPageByPathPreview
     */
    readonly inPreviewPanel?: boolean

    /**
     * 
     * @type {string}
     * @memberof WebshopApiGetPageByPathPreview
     */
    readonly token?: string
}

/**
 * Request parameters for getProduct operation in WebshopApi.
 * @export
 * @interface WebshopApiGetProductRequest
 */
export interface WebshopApiGetProductRequest {
    /**
     * A unique integer value identifying this Product.
     * @type {number}
     * @memberof WebshopApiGetProduct
     */
    readonly id: number
}

/**
 * Request parameters for getSocailMediaSettings operation in WebshopApi.
 * @export
 * @interface WebshopApiGetSocailMediaSettingsRequest
 */
export interface WebshopApiGetSocailMediaSettingsRequest {
    /**
     * A unique integer value identifying this Social media settings.
     * @type {number}
     * @memberof WebshopApiGetSocailMediaSettings
     */
    readonly id: number
}

/**
 * Request parameters for webshopApiLayoutSettingsList operation in WebshopApi.
 * @export
 * @interface WebshopApiWebshopApiLayoutSettingsListRequest
 */
export interface WebshopApiWebshopApiLayoutSettingsListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof WebshopApiWebshopApiLayoutSettingsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof WebshopApiWebshopApiLayoutSettingsList
     */
    readonly offset?: number
}

/**
 * Request parameters for webshopApiProductCreate operation in WebshopApi.
 * @export
 * @interface WebshopApiWebshopApiProductCreateRequest
 */
export interface WebshopApiWebshopApiProductCreateRequest {
    /**
     * 
     * @type {ProductRequest}
     * @memberof WebshopApiWebshopApiProductCreate
     */
    readonly productRequest: ProductRequest
}

/**
 * Request parameters for webshopApiProductDestroy operation in WebshopApi.
 * @export
 * @interface WebshopApiWebshopApiProductDestroyRequest
 */
export interface WebshopApiWebshopApiProductDestroyRequest {
    /**
     * A unique integer value identifying this Product.
     * @type {number}
     * @memberof WebshopApiWebshopApiProductDestroy
     */
    readonly id: number
}

/**
 * Request parameters for webshopApiProductList operation in WebshopApi.
 * @export
 * @interface WebshopApiWebshopApiProductListRequest
 */
export interface WebshopApiWebshopApiProductListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof WebshopApiWebshopApiProductList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof WebshopApiWebshopApiProductList
     */
    readonly offset?: number
}

/**
 * Request parameters for webshopApiProductPartialUpdate operation in WebshopApi.
 * @export
 * @interface WebshopApiWebshopApiProductPartialUpdateRequest
 */
export interface WebshopApiWebshopApiProductPartialUpdateRequest {
    /**
     * A unique integer value identifying this Product.
     * @type {number}
     * @memberof WebshopApiWebshopApiProductPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedProductRequest}
     * @memberof WebshopApiWebshopApiProductPartialUpdate
     */
    readonly patchedProductRequest?: PatchedProductRequest
}

/**
 * Request parameters for webshopApiProductUpdate operation in WebshopApi.
 * @export
 * @interface WebshopApiWebshopApiProductUpdateRequest
 */
export interface WebshopApiWebshopApiProductUpdateRequest {
    /**
     * A unique integer value identifying this Product.
     * @type {number}
     * @memberof WebshopApiWebshopApiProductUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ProductRequest}
     * @memberof WebshopApiWebshopApiProductUpdate
     */
    readonly productRequest: ProductRequest
}

/**
 * Request parameters for webshopApiSocialMediaSettingsList operation in WebshopApi.
 * @export
 * @interface WebshopApiWebshopApiSocialMediaSettingsListRequest
 */
export interface WebshopApiWebshopApiSocialMediaSettingsListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof WebshopApiWebshopApiSocialMediaSettingsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof WebshopApiWebshopApiSocialMediaSettingsList
     */
    readonly offset?: number
}

/**
 * WebshopApi - object-oriented interface
 * @export
 * @class WebshopApi
 * @extends {BaseAPI}
 */
export class WebshopApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public generateZipWithFiles(options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).generateZipWithFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public getAllPagePaths(options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).getAllPagePaths(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get exam files by subject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public getExamFilesBySubject(options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).getExamFilesBySubject(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebshopApiGetLayoutSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public getLayoutSettings(requestParameters: WebshopApiGetLayoutSettingsRequest, options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).getLayoutSettings(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebshopApiGetPageByPathRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public getPageByPath(requestParameters: WebshopApiGetPageByPathRequest, options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).getPageByPath(requestParameters.htmlPath, requestParameters.host, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebshopApiGetPageByPathPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public getPageByPathPreview(requestParameters: WebshopApiGetPageByPathPreviewRequest = {}, options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).getPageByPathPreview(requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebshopApiGetProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public getProduct(requestParameters: WebshopApiGetProductRequest, options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).getProduct(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebshopApiGetSocailMediaSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public getSocailMediaSettings(requestParameters: WebshopApiGetSocailMediaSettingsRequest, options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).getSocailMediaSettings(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebshopApiWebshopApiLayoutSettingsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public webshopApiLayoutSettingsList(requestParameters: WebshopApiWebshopApiLayoutSettingsListRequest = {}, options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).webshopApiLayoutSettingsList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebshopApiWebshopApiProductCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public webshopApiProductCreate(requestParameters: WebshopApiWebshopApiProductCreateRequest, options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).webshopApiProductCreate(requestParameters.productRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebshopApiWebshopApiProductDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public webshopApiProductDestroy(requestParameters: WebshopApiWebshopApiProductDestroyRequest, options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).webshopApiProductDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebshopApiWebshopApiProductListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public webshopApiProductList(requestParameters: WebshopApiWebshopApiProductListRequest = {}, options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).webshopApiProductList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebshopApiWebshopApiProductPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public webshopApiProductPartialUpdate(requestParameters: WebshopApiWebshopApiProductPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).webshopApiProductPartialUpdate(requestParameters.id, requestParameters.patchedProductRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebshopApiWebshopApiProductUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public webshopApiProductUpdate(requestParameters: WebshopApiWebshopApiProductUpdateRequest, options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).webshopApiProductUpdate(requestParameters.id, requestParameters.productRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebshopApiWebshopApiSocialMediaSettingsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebshopApi
     */
    public webshopApiSocialMediaSettingsList(requestParameters: WebshopApiWebshopApiSocialMediaSettingsListRequest = {}, options?: RawAxiosRequestConfig) {
        return WebshopApiFp(this.configuration).webshopApiSocialMediaSettingsList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}

