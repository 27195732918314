/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `description` - description * `lead_text` - lead_text * `table` - table
 * @export
 * @enum {string}
 */

export const ProblemBlockSharedTypeEnum = {
    Description: 'description',
    LeadText: 'lead_text',
    Table: 'table'
} as const;

export type ProblemBlockSharedTypeEnum = typeof ProblemBlockSharedTypeEnum[keyof typeof ProblemBlockSharedTypeEnum];



