/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `IconMTriangularRuler` - M triangular ruler * `IconMDna` - M dna * `IconMRocket` - M rocket * `IconMNib` - M nib * `IconMStatueofLiberty` - M statueof liberty * `IconMMemo` - M memo * `IconMStraightRuler` - M straight ruler * `IconMLightBulb` - M light bulb
 * @export
 * @enum {string}
 */

export const InteractiveTabsItemIconEnum = {
    IconMTriangularRuler: 'IconMTriangularRuler',
    IconMDna: 'IconMDna',
    IconMRocket: 'IconMRocket',
    IconMNib: 'IconMNib',
    IconMStatueofLiberty: 'IconMStatueofLiberty',
    IconMMemo: 'IconMMemo',
    IconMStraightRuler: 'IconMStraightRuler',
    IconMLightBulb: 'IconMLightBulb'
} as const;

export type InteractiveTabsItemIconEnum = typeof InteractiveTabsItemIconEnum[keyof typeof InteractiveTabsItemIconEnum];



