/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `free` - Free * `account_required` - Account required * `cooming_soon` - Coming soon * `hidden` - Hidden
 * @export
 * @enum {string}
 */

export const AvailabilityStatusEnum = {
    Free: 'free',
    AccountRequired: 'account_required',
    CoomingSoon: 'cooming_soon',
    Hidden: 'hidden'
} as const;

export type AvailabilityStatusEnum = typeof AvailabilityStatusEnum[keyof typeof AvailabilityStatusEnum];



