/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Course } from '../models';
// @ts-ignore
import type { CourseCourseModules } from '../models';
// @ts-ignore
import type { CourseDigitalTextbook } from '../models';
// @ts-ignore
import type { CourseProblemSourceListings } from '../models';
// @ts-ignore
import type { CourseVideoListing } from '../models';
// @ts-ignore
import type { DigitalTextbook } from '../models';
// @ts-ignore
import type { DigitalTextbookListings } from '../models';
// @ts-ignore
import type { DigitalTextbookPrintPreview } from '../models';
// @ts-ignore
import type { DigitalTextbookSection } from '../models';
// @ts-ignore
import type { DigitalTextbookSectionPrintPreview } from '../models';
// @ts-ignore
import type { DigitalTextbookSectionPublic } from '../models';
// @ts-ignore
import type { DigitalTextbookWorkbook } from '../models';
// @ts-ignore
import type { DigitalTextbookWorkbookSection } from '../models';
// @ts-ignore
import type { PaginatedCourseListList } from '../models';
// @ts-ignore
import type { PaginatedDigitalTextbookListList } from '../models';
// @ts-ignore
import type { PaginatedDigitalTextbookSectionListList } from '../models';
// @ts-ignore
import type { PaginatedProblemListList } from '../models';
// @ts-ignore
import type { PaginatedProblemSourceListList } from '../models';
// @ts-ignore
import type { PaginatedUserDataFormAttemptListList } from '../models';
// @ts-ignore
import type { PaginatedUserDataFormListList } from '../models';
// @ts-ignore
import type { PaginatedUserDataFormStepList } from '../models';
// @ts-ignore
import type { PaginatedUserList } from '../models';
// @ts-ignore
import type { PatchedProblemSourceRequest } from '../models';
// @ts-ignore
import type { PatchedProblemUpdateRequest } from '../models';
// @ts-ignore
import type { PatchedUserDataFormAttemptRequest } from '../models';
// @ts-ignore
import type { Problem } from '../models';
// @ts-ignore
import type { ProblemRequest } from '../models';
// @ts-ignore
import type { ProblemSource } from '../models';
// @ts-ignore
import type { ProblemSourceRequest } from '../models';
// @ts-ignore
import type { User } from '../models';
// @ts-ignore
import type { UserDataForm } from '../models';
// @ts-ignore
import type { UserDataFormAttempt } from '../models';
// @ts-ignore
import type { UserDataFormAttemptRequest } from '../models';
// @ts-ignore
import type { UserDataFormResponses } from '../models';
// @ts-ignore
import type { UserDataFormStep } from '../models';
// @ts-ignore
import type { VimeoFoldersResponse } from '../models';
// @ts-ignore
import type { VimeoUploadLinkRequestRequest } from '../models';
// @ts-ignore
import type { VimeoUploadLinkResponse } from '../models';
/**
 * SharedApi - axios parameter creator
 * @export
 */
export const SharedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse: async (id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourse', 'id', id)
            const localVarPath = `/wt/shared/api/course/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }

            if (inPreviewPanel !== undefined) {
                localVarQueryParameter['in_preview_panel'] = inPreviewPanel;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCourseModules: async (id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourseCourseModules', 'id', id)
            const localVarPath = `/wt/shared/api/course/{id}/retrieve_with_course_modules/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }

            if (inPreviewPanel !== undefined) {
                localVarQueryParameter['in_preview_panel'] = inPreviewPanel;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseDigitalTextbook: async (id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourseDigitalTextbook', 'id', id)
            const localVarPath = `/wt/shared/api/course/{id}/retrieve_with_digital_textbook/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }

            if (inPreviewPanel !== undefined) {
                localVarQueryParameter['in_preview_panel'] = inPreviewPanel;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [digitalTextbook] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseList: async (digitalTextbook?: number, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/course/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (digitalTextbook !== undefined) {
                localVarQueryParameter['digital_textbook'] = digitalTextbook;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseProblemSourceListings: async (id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourseProblemSourceListings', 'id', id)
            const localVarPath = `/wt/shared/api/course/{id}/retrieve_with_problem_source_listings/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }

            if (inPreviewPanel !== undefined) {
                localVarQueryParameter['in_preview_panel'] = inPreviewPanel;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseVideoListings: async (id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourseVideoListings', 'id', id)
            const localVarPath = `/wt/shared/api/course/{id}/retrieve_with_video_listings/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }

            if (inPreviewPanel !== undefined) {
                localVarQueryParameter['in_preview_panel'] = inPreviewPanel;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbook: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDigitalTextbook', 'id', id)
            const localVarPath = `/wt/shared/api/digital-textbook/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [digitalTextbookSubject] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookList: async (digitalTextbookSubject?: number, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/digital-textbook/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (digitalTextbookSubject !== undefined) {
                localVarQueryParameter['digital_textbook_subject'] = digitalTextbookSubject;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookListings: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDigitalTextbookListings', 'id', id)
            const localVarPath = `/wt/shared/api/digital-textbook/{id}/retrieve-listings/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookPrintPreview: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDigitalTextbookPrintPreview', 'id', id)
            const localVarPath = `/wt/shared/api/digital-textbook/{id}/print-preview/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook section.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookSection: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDigitalTextbookSection', 'id', id)
            const localVarPath = `/wt/shared/api/digital-textbook-section/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookSectionList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/digital-textbook-section/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook section.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookSectionPrintPreview: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDigitalTextbookSectionPrintPreview', 'id', id)
            const localVarPath = `/wt/shared/api/digital-textbook-section/{id}/retrieve_for_print/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook section.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookSectionPublic: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDigitalTextbookSectionPublic', 'id', id)
            const localVarPath = `/wt/shared/api/digital-textbook-section/{id}/retrieve_for_public/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookWorkbook: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDigitalTextbookWorkbook', 'id', id)
            const localVarPath = `/wt/shared/api/digital-textbook/{id}/workbook/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook section.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookWorkbookSection: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDigitalTextbookWorkbookSection', 'id', id)
            const localVarPath = `/wt/shared/api/digital-textbook-section/{id}/workbook-section/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblem: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProblem', 'id', id)
            const localVarPath = `/wt/shared/api/problem/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/problem/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSource: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProblemSource', 'id', id)
            const localVarPath = `/wt/shared/api/problem-source/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetProblemSourceListLevelEnum} [level] 
         * @param {boolean} [levelIsnull] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [problemSourceSubject] 
         * @param {GetProblemSourceListTypeEnum} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSourceList: async (level?: GetProblemSourceListLevelEnum, levelIsnull?: boolean, limit?: number, offset?: number, ordering?: string, problemSourceSubject?: number, type?: GetProblemSourceListTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/problem-source/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (levelIsnull !== undefined) {
                localVarQueryParameter['level__isnull'] = levelIsnull;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (problemSourceSubject !== undefined) {
                localVarQueryParameter['problem_source_subject'] = problemSourceSubject;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user data form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataForm: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserDataForm', 'id', id)
            const localVarPath = `/wt/shared/api/user-data-form/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormAttempt: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserDataFormAttempt', 'id', id)
            const localVarPath = `/wt/shared/api/user-data-form-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [userDataForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormAttemptList: async (limit?: number, offset?: number, userDataForm?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/user-data-form-attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (userDataForm !== undefined) {
                localVarQueryParameter['user_data_form'] = userDataForm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/user-data-form/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user data form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormResponses: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserDataFormResponses', 'id', id)
            const localVarPath = `/wt/shared/api/user-data-form/{id}/responses/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user data form step.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormStep: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserDataFormStep', 'id', id)
            const localVarPath = `/wt/shared/api/user-data-form-screen/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fields] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVimeoFolders: async (fields?: string, page?: number, perPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/vimeo/folders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<VimeoUploadLinkRequestRequest>} vimeoUploadLinkRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVimeoUploadLinkBatch: async (vimeoUploadLinkRequestRequest: Array<VimeoUploadLinkRequestRequest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vimeoUploadLinkRequestRequest' is not null or undefined
            assertParamExists('getVimeoUploadLinkBatch', 'vimeoUploadLinkRequestRequest', vimeoUploadLinkRequestRequest)
            const localVarPath = `/wt/shared/api/vimeo/upload-link-batch/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vimeoUploadLinkRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send account activation email
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAccountActivationEmail: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendAccountActivationEmail', 'id', id)
            const localVarPath = `/wt/shared/api/user/{id}/send-activation-email/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send confirmation email
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendConfirmationEmail: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendConfirmationEmail', 'id', id)
            const localVarPath = `/wt/shared/api/user/{id}/send-confirmation-email/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiCoursePreviewRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/course/preview/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProblemRequest} problemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemCreate: async (problemRequest: ProblemRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'problemRequest' is not null or undefined
            assertParamExists('sharedApiProblemCreate', 'problemRequest', problemRequest)
            const localVarPath = `/wt/shared/api/problem/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(problemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiProblemDestroy', 'id', id)
            const localVarPath = `/wt/shared/api/problem/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProblemSourceRequest} problemSourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemSourceCreate: async (problemSourceRequest: ProblemSourceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'problemSourceRequest' is not null or undefined
            assertParamExists('sharedApiProblemSourceCreate', 'problemSourceRequest', problemSourceRequest)
            const localVarPath = `/wt/shared/api/problem-source/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(problemSourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemSourceDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiProblemSourceDestroy', 'id', id)
            const localVarPath = `/wt/shared/api/problem-source/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {PatchedProblemSourceRequest} [patchedProblemSourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemSourcePartialUpdate: async (id: number, patchedProblemSourceRequest?: PatchedProblemSourceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiProblemSourcePartialUpdate', 'id', id)
            const localVarPath = `/wt/shared/api/problem-source/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedProblemSourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {ProblemSourceRequest} problemSourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemSourceUpdate: async (id: number, problemSourceRequest: ProblemSourceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiProblemSourceUpdate', 'id', id)
            // verify required parameter 'problemSourceRequest' is not null or undefined
            assertParamExists('sharedApiProblemSourceUpdate', 'problemSourceRequest', problemSourceRequest)
            const localVarPath = `/wt/shared/api/problem-source/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(problemSourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem.
         * @param {ProblemRequest} problemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemUpdate: async (id: number, problemRequest: ProblemRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiProblemUpdate', 'id', id)
            // verify required parameter 'problemRequest' is not null or undefined
            assertParamExists('sharedApiProblemUpdate', 'problemRequest', problemRequest)
            const localVarPath = `/wt/shared/api/problem/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(problemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserDataFormAttemptRequest} userDataFormAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptCreate: async (userDataFormAttemptRequest: UserDataFormAttemptRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDataFormAttemptRequest' is not null or undefined
            assertParamExists('sharedApiUserDataFormAttemptCreate', 'userDataFormAttemptRequest', userDataFormAttemptRequest)
            const localVarPath = `/wt/shared/api/user-data-form-attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDataFormAttemptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiUserDataFormAttemptDestroy', 'id', id)
            const localVarPath = `/wt/shared/api/user-data-form-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {PatchedUserDataFormAttemptRequest} [patchedUserDataFormAttemptRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptPartialUpdate: async (id: number, patchedUserDataFormAttemptRequest?: PatchedUserDataFormAttemptRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiUserDataFormAttemptPartialUpdate', 'id', id)
            const localVarPath = `/wt/shared/api/user-data-form-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUserDataFormAttemptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {UserDataFormAttemptRequest} userDataFormAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptUpdate: async (id: number, userDataFormAttemptRequest: UserDataFormAttemptRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiUserDataFormAttemptUpdate', 'id', id)
            // verify required parameter 'userDataFormAttemptRequest' is not null or undefined
            assertParamExists('sharedApiUserDataFormAttemptUpdate', 'userDataFormAttemptRequest', userDataFormAttemptRequest)
            const localVarPath = `/wt/shared/api/user-data-form-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDataFormAttemptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormScreenList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/user-data-form-screen/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiUserRetrieve', 'id', id)
            const localVarPath = `/wt/shared/api/user/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiVimeoCreateFolderCreate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/vimeo/create_folder/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserDataFormAttemptRequest} userDataFormAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrCreateUserDataFormAttempt: async (userDataFormAttemptRequest: UserDataFormAttemptRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDataFormAttemptRequest' is not null or undefined
            assertParamExists('updateOrCreateUserDataFormAttempt', 'userDataFormAttemptRequest', userDataFormAttemptRequest)
            const localVarPath = `/wt/shared/api/user-data-form-attempt/update-or-create/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDataFormAttemptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem.
         * @param {PatchedProblemUpdateRequest} [patchedProblemUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProblem: async (id: number, patchedProblemUpdateRequest?: PatchedProblemUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProblem', 'id', id)
            const localVarPath = `/wt/shared/api/problem/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedProblemUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SharedApi - functional programming interface
 * @export
 */
export const SharedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SharedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourse(id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Course>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourse(id, contentType, inPreviewPanel, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseCourseModules(id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseCourseModules>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseCourseModules(id, contentType, inPreviewPanel, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getCourseCourseModules']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseDigitalTextbook(id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDigitalTextbook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseDigitalTextbook(id, contentType, inPreviewPanel, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getCourseDigitalTextbook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [digitalTextbook] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseList(digitalTextbook?: number, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCourseListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseList(digitalTextbook, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getCourseList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseProblemSourceListings(id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseProblemSourceListings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseProblemSourceListings(id, contentType, inPreviewPanel, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getCourseProblemSourceListings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseVideoListings(id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseVideoListing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseVideoListings(id, contentType, inPreviewPanel, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getCourseVideoListings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbook(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DigitalTextbook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbook(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getDigitalTextbook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [digitalTextbookSubject] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbookList(digitalTextbookSubject?: number, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDigitalTextbookListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbookList(digitalTextbookSubject, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getDigitalTextbookList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbookListings(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DigitalTextbookListings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbookListings(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getDigitalTextbookListings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbookPrintPreview(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DigitalTextbookPrintPreview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbookPrintPreview(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getDigitalTextbookPrintPreview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook section.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbookSection(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DigitalTextbookSection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbookSection(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getDigitalTextbookSection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbookSectionList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDigitalTextbookSectionListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbookSectionList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getDigitalTextbookSectionList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook section.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbookSectionPrintPreview(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DigitalTextbookSectionPrintPreview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbookSectionPrintPreview(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getDigitalTextbookSectionPrintPreview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook section.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbookSectionPublic(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DigitalTextbookSectionPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbookSectionPublic(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getDigitalTextbookSectionPublic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbookWorkbook(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DigitalTextbookWorkbook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbookWorkbook(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getDigitalTextbookWorkbook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook section.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbookWorkbookSection(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DigitalTextbookWorkbookSection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbookWorkbookSection(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getDigitalTextbookWorkbookSection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblem(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Problem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getProblem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProblemListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getProblemList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemSource(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemSource(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getProblemSource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetProblemSourceListLevelEnum} [level] 
         * @param {boolean} [levelIsnull] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [problemSourceSubject] 
         * @param {GetProblemSourceListTypeEnum} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemSourceList(level?: GetProblemSourceListLevelEnum, levelIsnull?: boolean, limit?: number, offset?: number, ordering?: string, problemSourceSubject?: number, type?: GetProblemSourceListTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProblemSourceListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemSourceList(level, levelIsnull, limit, offset, ordering, problemSourceSubject, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getProblemSourceList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user data form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDataForm(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataForm(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getUserDataForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDataFormAttempt(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataFormAttempt(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getUserDataFormAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [userDataForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDataFormAttemptList(limit?: number, offset?: number, userDataForm?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedUserDataFormAttemptListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataFormAttemptList(limit, offset, userDataForm, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getUserDataFormAttemptList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDataFormList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedUserDataFormListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataFormList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getUserDataFormList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user data form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDataFormResponses(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormResponses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataFormResponses(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getUserDataFormResponses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user data form step.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDataFormStep(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataFormStep(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getUserDataFormStep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fields] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVimeoFolders(fields?: string, page?: number, perPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimeoFoldersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVimeoFolders(fields, page, perPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getVimeoFolders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<VimeoUploadLinkRequestRequest>} vimeoUploadLinkRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVimeoUploadLinkBatch(vimeoUploadLinkRequestRequest: Array<VimeoUploadLinkRequestRequest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VimeoUploadLinkResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVimeoUploadLinkBatch(vimeoUploadLinkRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getVimeoUploadLinkBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send account activation email
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAccountActivationEmail(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendAccountActivationEmail(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sendAccountActivationEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send confirmation email
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendConfirmationEmail(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendConfirmationEmail(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sendConfirmationEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiCoursePreviewRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Course>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiCoursePreviewRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiCoursePreviewRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProblemRequest} problemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiProblemCreate(problemRequest: ProblemRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Problem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiProblemCreate(problemRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiProblemCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiProblemDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiProblemDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiProblemDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProblemSourceRequest} problemSourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiProblemSourceCreate(problemSourceRequest: ProblemSourceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiProblemSourceCreate(problemSourceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiProblemSourceCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiProblemSourceDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiProblemSourceDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiProblemSourceDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {PatchedProblemSourceRequest} [patchedProblemSourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiProblemSourcePartialUpdate(id: number, patchedProblemSourceRequest?: PatchedProblemSourceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiProblemSourcePartialUpdate(id, patchedProblemSourceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiProblemSourcePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {ProblemSourceRequest} problemSourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiProblemSourceUpdate(id: number, problemSourceRequest: ProblemSourceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiProblemSourceUpdate(id, problemSourceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiProblemSourceUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem.
         * @param {ProblemRequest} problemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiProblemUpdate(id: number, problemRequest: ProblemRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Problem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiProblemUpdate(id, problemRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiProblemUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserDataFormAttemptRequest} userDataFormAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserDataFormAttemptCreate(userDataFormAttemptRequest: UserDataFormAttemptRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserDataFormAttemptCreate(userDataFormAttemptRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserDataFormAttemptCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserDataFormAttemptDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserDataFormAttemptDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserDataFormAttemptDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {PatchedUserDataFormAttemptRequest} [patchedUserDataFormAttemptRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserDataFormAttemptPartialUpdate(id: number, patchedUserDataFormAttemptRequest?: PatchedUserDataFormAttemptRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserDataFormAttemptPartialUpdate(id, patchedUserDataFormAttemptRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserDataFormAttemptPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {UserDataFormAttemptRequest} userDataFormAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserDataFormAttemptUpdate(id: number, userDataFormAttemptRequest: UserDataFormAttemptRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserDataFormAttemptUpdate(id, userDataFormAttemptRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserDataFormAttemptUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserDataFormScreenList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedUserDataFormStepList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserDataFormScreenList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserDataFormScreenList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiVimeoCreateFolderCreate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiVimeoCreateFolderCreate(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiVimeoCreateFolderCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserDataFormAttemptRequest} userDataFormAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrCreateUserDataFormAttempt(userDataFormAttemptRequest: UserDataFormAttemptRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrCreateUserDataFormAttempt(userDataFormAttemptRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.updateOrCreateUserDataFormAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem.
         * @param {PatchedProblemUpdateRequest} [patchedProblemUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProblem(id: number, patchedProblemUpdateRequest?: PatchedProblemUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Problem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProblem(id, patchedProblemUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.updateProblem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SharedApi - factory interface
 * @export
 */
export const SharedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SharedApiFp(configuration)
    return {
        /**
         * 
         * @param {SharedApiGetCourseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse(requestParameters: SharedApiGetCourseRequest, options?: RawAxiosRequestConfig): AxiosPromise<Course> {
            return localVarFp.getCourse(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetCourseCourseModulesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCourseModules(requestParameters: SharedApiGetCourseCourseModulesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CourseCourseModules> {
            return localVarFp.getCourseCourseModules(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetCourseDigitalTextbookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseDigitalTextbook(requestParameters: SharedApiGetCourseDigitalTextbookRequest, options?: RawAxiosRequestConfig): AxiosPromise<CourseDigitalTextbook> {
            return localVarFp.getCourseDigitalTextbook(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetCourseListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseList(requestParameters: SharedApiGetCourseListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedCourseListList> {
            return localVarFp.getCourseList(requestParameters.digitalTextbook, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetCourseProblemSourceListingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseProblemSourceListings(requestParameters: SharedApiGetCourseProblemSourceListingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CourseProblemSourceListings> {
            return localVarFp.getCourseProblemSourceListings(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetCourseVideoListingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseVideoListings(requestParameters: SharedApiGetCourseVideoListingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CourseVideoListing> {
            return localVarFp.getCourseVideoListings(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetDigitalTextbookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbook(requestParameters: SharedApiGetDigitalTextbookRequest, options?: RawAxiosRequestConfig): AxiosPromise<DigitalTextbook> {
            return localVarFp.getDigitalTextbook(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetDigitalTextbookListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookList(requestParameters: SharedApiGetDigitalTextbookListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedDigitalTextbookListList> {
            return localVarFp.getDigitalTextbookList(requestParameters.digitalTextbookSubject, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetDigitalTextbookListingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookListings(requestParameters: SharedApiGetDigitalTextbookListingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<DigitalTextbookListings> {
            return localVarFp.getDigitalTextbookListings(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetDigitalTextbookPrintPreviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookPrintPreview(requestParameters: SharedApiGetDigitalTextbookPrintPreviewRequest, options?: RawAxiosRequestConfig): AxiosPromise<DigitalTextbookPrintPreview> {
            return localVarFp.getDigitalTextbookPrintPreview(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetDigitalTextbookSectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookSection(requestParameters: SharedApiGetDigitalTextbookSectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DigitalTextbookSection> {
            return localVarFp.getDigitalTextbookSection(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetDigitalTextbookSectionListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookSectionList(requestParameters: SharedApiGetDigitalTextbookSectionListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedDigitalTextbookSectionListList> {
            return localVarFp.getDigitalTextbookSectionList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetDigitalTextbookSectionPrintPreviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookSectionPrintPreview(requestParameters: SharedApiGetDigitalTextbookSectionPrintPreviewRequest, options?: RawAxiosRequestConfig): AxiosPromise<DigitalTextbookSectionPrintPreview> {
            return localVarFp.getDigitalTextbookSectionPrintPreview(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetDigitalTextbookSectionPublicRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookSectionPublic(requestParameters: SharedApiGetDigitalTextbookSectionPublicRequest, options?: RawAxiosRequestConfig): AxiosPromise<DigitalTextbookSectionPublic> {
            return localVarFp.getDigitalTextbookSectionPublic(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetDigitalTextbookWorkbookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookWorkbook(requestParameters: SharedApiGetDigitalTextbookWorkbookRequest, options?: RawAxiosRequestConfig): AxiosPromise<DigitalTextbookWorkbook> {
            return localVarFp.getDigitalTextbookWorkbook(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetDigitalTextbookWorkbookSectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookWorkbookSection(requestParameters: SharedApiGetDigitalTextbookWorkbookSectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DigitalTextbookWorkbookSection> {
            return localVarFp.getDigitalTextbookWorkbookSection(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetProblemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblem(requestParameters: SharedApiGetProblemRequest, options?: RawAxiosRequestConfig): AxiosPromise<Problem> {
            return localVarFp.getProblem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetProblemListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemList(requestParameters: SharedApiGetProblemListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProblemListList> {
            return localVarFp.getProblemList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetProblemSourceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSource(requestParameters: SharedApiGetProblemSourceRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemSource> {
            return localVarFp.getProblemSource(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetProblemSourceListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSourceList(requestParameters: SharedApiGetProblemSourceListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProblemSourceListList> {
            return localVarFp.getProblemSourceList(requestParameters.level, requestParameters.levelIsnull, requestParameters.limit, requestParameters.offset, requestParameters.ordering, requestParameters.problemSourceSubject, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetUserDataFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataForm(requestParameters: SharedApiGetUserDataFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataForm> {
            return localVarFp.getUserDataForm(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetUserDataFormAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormAttempt(requestParameters: SharedApiGetUserDataFormAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormAttempt> {
            return localVarFp.getUserDataFormAttempt(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetUserDataFormAttemptListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormAttemptList(requestParameters: SharedApiGetUserDataFormAttemptListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedUserDataFormAttemptListList> {
            return localVarFp.getUserDataFormAttemptList(requestParameters.limit, requestParameters.offset, requestParameters.userDataForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetUserDataFormListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormList(requestParameters: SharedApiGetUserDataFormListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedUserDataFormListList> {
            return localVarFp.getUserDataFormList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetUserDataFormResponsesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormResponses(requestParameters: SharedApiGetUserDataFormResponsesRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormResponses> {
            return localVarFp.getUserDataFormResponses(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetUserDataFormStepRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormStep(requestParameters: SharedApiGetUserDataFormStepRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormStep> {
            return localVarFp.getUserDataFormStep(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetVimeoFoldersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVimeoFolders(requestParameters: SharedApiGetVimeoFoldersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<VimeoFoldersResponse> {
            return localVarFp.getVimeoFolders(requestParameters.fields, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetVimeoUploadLinkBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVimeoUploadLinkBatch(requestParameters: SharedApiGetVimeoUploadLinkBatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<VimeoUploadLinkResponse>> {
            return localVarFp.getVimeoUploadLinkBatch(requestParameters.vimeoUploadLinkRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send account activation email
         * @param {SharedApiSendAccountActivationEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAccountActivationEmail(requestParameters: SharedApiSendAccountActivationEmailRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sendAccountActivationEmail(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send confirmation email
         * @param {SharedApiSendConfirmationEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendConfirmationEmail(requestParameters: SharedApiSendConfirmationEmailRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sendConfirmationEmail(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiCoursePreviewRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Course> {
            return localVarFp.sharedApiCoursePreviewRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiProblemCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemCreate(requestParameters: SharedApiSharedApiProblemCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Problem> {
            return localVarFp.sharedApiProblemCreate(requestParameters.problemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiProblemDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemDestroy(requestParameters: SharedApiSharedApiProblemDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sharedApiProblemDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiProblemSourceCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemSourceCreate(requestParameters: SharedApiSharedApiProblemSourceCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemSource> {
            return localVarFp.sharedApiProblemSourceCreate(requestParameters.problemSourceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiProblemSourceDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemSourceDestroy(requestParameters: SharedApiSharedApiProblemSourceDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sharedApiProblemSourceDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiProblemSourcePartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemSourcePartialUpdate(requestParameters: SharedApiSharedApiProblemSourcePartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemSource> {
            return localVarFp.sharedApiProblemSourcePartialUpdate(requestParameters.id, requestParameters.patchedProblemSourceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiProblemSourceUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemSourceUpdate(requestParameters: SharedApiSharedApiProblemSourceUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemSource> {
            return localVarFp.sharedApiProblemSourceUpdate(requestParameters.id, requestParameters.problemSourceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiProblemUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiProblemUpdate(requestParameters: SharedApiSharedApiProblemUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Problem> {
            return localVarFp.sharedApiProblemUpdate(requestParameters.id, requestParameters.problemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserDataFormAttemptCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptCreate(requestParameters: SharedApiSharedApiUserDataFormAttemptCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormAttempt> {
            return localVarFp.sharedApiUserDataFormAttemptCreate(requestParameters.userDataFormAttemptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserDataFormAttemptDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptDestroy(requestParameters: SharedApiSharedApiUserDataFormAttemptDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sharedApiUserDataFormAttemptDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserDataFormAttemptPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptPartialUpdate(requestParameters: SharedApiSharedApiUserDataFormAttemptPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormAttempt> {
            return localVarFp.sharedApiUserDataFormAttemptPartialUpdate(requestParameters.id, requestParameters.patchedUserDataFormAttemptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserDataFormAttemptUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptUpdate(requestParameters: SharedApiSharedApiUserDataFormAttemptUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormAttempt> {
            return localVarFp.sharedApiUserDataFormAttemptUpdate(requestParameters.id, requestParameters.userDataFormAttemptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserDataFormScreenListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormScreenList(requestParameters: SharedApiSharedApiUserDataFormScreenListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedUserDataFormStepList> {
            return localVarFp.sharedApiUserDataFormScreenList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserList(requestParameters: SharedApiSharedApiUserListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedUserList> {
            return localVarFp.sharedApiUserList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserRetrieve(requestParameters: SharedApiSharedApiUserRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.sharedApiUserRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiVimeoCreateFolderCreate(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sharedApiVimeoCreateFolderCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiUpdateOrCreateUserDataFormAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrCreateUserDataFormAttempt(requestParameters: SharedApiUpdateOrCreateUserDataFormAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormAttempt> {
            return localVarFp.updateOrCreateUserDataFormAttempt(requestParameters.userDataFormAttemptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiUpdateProblemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProblem(requestParameters: SharedApiUpdateProblemRequest, options?: RawAxiosRequestConfig): AxiosPromise<Problem> {
            return localVarFp.updateProblem(requestParameters.id, requestParameters.patchedProblemUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCourse operation in SharedApi.
 * @export
 * @interface SharedApiGetCourseRequest
 */
export interface SharedApiGetCourseRequest {
    /**
     * A unique integer value identifying this course.
     * @type {number}
     * @memberof SharedApiGetCourse
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourse
     */
    readonly contentType?: string

    /**
     * 
     * @type {boolean}
     * @memberof SharedApiGetCourse
     */
    readonly inPreviewPanel?: boolean

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourse
     */
    readonly token?: string
}

/**
 * Request parameters for getCourseCourseModules operation in SharedApi.
 * @export
 * @interface SharedApiGetCourseCourseModulesRequest
 */
export interface SharedApiGetCourseCourseModulesRequest {
    /**
     * A unique integer value identifying this course.
     * @type {number}
     * @memberof SharedApiGetCourseCourseModules
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseCourseModules
     */
    readonly contentType?: string

    /**
     * 
     * @type {boolean}
     * @memberof SharedApiGetCourseCourseModules
     */
    readonly inPreviewPanel?: boolean

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseCourseModules
     */
    readonly token?: string
}

/**
 * Request parameters for getCourseDigitalTextbook operation in SharedApi.
 * @export
 * @interface SharedApiGetCourseDigitalTextbookRequest
 */
export interface SharedApiGetCourseDigitalTextbookRequest {
    /**
     * A unique integer value identifying this course.
     * @type {number}
     * @memberof SharedApiGetCourseDigitalTextbook
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseDigitalTextbook
     */
    readonly contentType?: string

    /**
     * 
     * @type {boolean}
     * @memberof SharedApiGetCourseDigitalTextbook
     */
    readonly inPreviewPanel?: boolean

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseDigitalTextbook
     */
    readonly token?: string
}

/**
 * Request parameters for getCourseList operation in SharedApi.
 * @export
 * @interface SharedApiGetCourseListRequest
 */
export interface SharedApiGetCourseListRequest {
    /**
     * 
     * @type {number}
     * @memberof SharedApiGetCourseList
     */
    readonly digitalTextbook?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiGetCourseList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiGetCourseList
     */
    readonly offset?: number
}

/**
 * Request parameters for getCourseProblemSourceListings operation in SharedApi.
 * @export
 * @interface SharedApiGetCourseProblemSourceListingsRequest
 */
export interface SharedApiGetCourseProblemSourceListingsRequest {
    /**
     * A unique integer value identifying this course.
     * @type {number}
     * @memberof SharedApiGetCourseProblemSourceListings
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseProblemSourceListings
     */
    readonly contentType?: string

    /**
     * 
     * @type {boolean}
     * @memberof SharedApiGetCourseProblemSourceListings
     */
    readonly inPreviewPanel?: boolean

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseProblemSourceListings
     */
    readonly token?: string
}

/**
 * Request parameters for getCourseVideoListings operation in SharedApi.
 * @export
 * @interface SharedApiGetCourseVideoListingsRequest
 */
export interface SharedApiGetCourseVideoListingsRequest {
    /**
     * A unique integer value identifying this course.
     * @type {number}
     * @memberof SharedApiGetCourseVideoListings
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseVideoListings
     */
    readonly contentType?: string

    /**
     * 
     * @type {boolean}
     * @memberof SharedApiGetCourseVideoListings
     */
    readonly inPreviewPanel?: boolean

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseVideoListings
     */
    readonly token?: string
}

/**
 * Request parameters for getDigitalTextbook operation in SharedApi.
 * @export
 * @interface SharedApiGetDigitalTextbookRequest
 */
export interface SharedApiGetDigitalTextbookRequest {
    /**
     * A unique integer value identifying this digital textbook.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbook
     */
    readonly id: number
}

/**
 * Request parameters for getDigitalTextbookList operation in SharedApi.
 * @export
 * @interface SharedApiGetDigitalTextbookListRequest
 */
export interface SharedApiGetDigitalTextbookListRequest {
    /**
     * 
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookList
     */
    readonly digitalTextbookSubject?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookList
     */
    readonly offset?: number
}

/**
 * Request parameters for getDigitalTextbookListings operation in SharedApi.
 * @export
 * @interface SharedApiGetDigitalTextbookListingsRequest
 */
export interface SharedApiGetDigitalTextbookListingsRequest {
    /**
     * A unique integer value identifying this digital textbook.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookListings
     */
    readonly id: number
}

/**
 * Request parameters for getDigitalTextbookPrintPreview operation in SharedApi.
 * @export
 * @interface SharedApiGetDigitalTextbookPrintPreviewRequest
 */
export interface SharedApiGetDigitalTextbookPrintPreviewRequest {
    /**
     * A unique integer value identifying this digital textbook.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookPrintPreview
     */
    readonly id: number
}

/**
 * Request parameters for getDigitalTextbookSection operation in SharedApi.
 * @export
 * @interface SharedApiGetDigitalTextbookSectionRequest
 */
export interface SharedApiGetDigitalTextbookSectionRequest {
    /**
     * A unique integer value identifying this digital textbook section.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookSection
     */
    readonly id: number
}

/**
 * Request parameters for getDigitalTextbookSectionList operation in SharedApi.
 * @export
 * @interface SharedApiGetDigitalTextbookSectionListRequest
 */
export interface SharedApiGetDigitalTextbookSectionListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookSectionList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookSectionList
     */
    readonly offset?: number
}

/**
 * Request parameters for getDigitalTextbookSectionPrintPreview operation in SharedApi.
 * @export
 * @interface SharedApiGetDigitalTextbookSectionPrintPreviewRequest
 */
export interface SharedApiGetDigitalTextbookSectionPrintPreviewRequest {
    /**
     * A unique integer value identifying this digital textbook section.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookSectionPrintPreview
     */
    readonly id: number
}

/**
 * Request parameters for getDigitalTextbookSectionPublic operation in SharedApi.
 * @export
 * @interface SharedApiGetDigitalTextbookSectionPublicRequest
 */
export interface SharedApiGetDigitalTextbookSectionPublicRequest {
    /**
     * A unique integer value identifying this digital textbook section.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookSectionPublic
     */
    readonly id: number
}

/**
 * Request parameters for getDigitalTextbookWorkbook operation in SharedApi.
 * @export
 * @interface SharedApiGetDigitalTextbookWorkbookRequest
 */
export interface SharedApiGetDigitalTextbookWorkbookRequest {
    /**
     * A unique integer value identifying this digital textbook.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookWorkbook
     */
    readonly id: number
}

/**
 * Request parameters for getDigitalTextbookWorkbookSection operation in SharedApi.
 * @export
 * @interface SharedApiGetDigitalTextbookWorkbookSectionRequest
 */
export interface SharedApiGetDigitalTextbookWorkbookSectionRequest {
    /**
     * A unique integer value identifying this digital textbook section.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookWorkbookSection
     */
    readonly id: number
}

/**
 * Request parameters for getProblem operation in SharedApi.
 * @export
 * @interface SharedApiGetProblemRequest
 */
export interface SharedApiGetProblemRequest {
    /**
     * A unique integer value identifying this problem.
     * @type {number}
     * @memberof SharedApiGetProblem
     */
    readonly id: number
}

/**
 * Request parameters for getProblemList operation in SharedApi.
 * @export
 * @interface SharedApiGetProblemListRequest
 */
export interface SharedApiGetProblemListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiGetProblemList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiGetProblemList
     */
    readonly offset?: number
}

/**
 * Request parameters for getProblemSource operation in SharedApi.
 * @export
 * @interface SharedApiGetProblemSourceRequest
 */
export interface SharedApiGetProblemSourceRequest {
    /**
     * A unique integer value identifying this problem source.
     * @type {number}
     * @memberof SharedApiGetProblemSource
     */
    readonly id: number
}

/**
 * Request parameters for getProblemSourceList operation in SharedApi.
 * @export
 * @interface SharedApiGetProblemSourceListRequest
 */
export interface SharedApiGetProblemSourceListRequest {
    /**
     * 
     * @type {'A' | 'B'}
     * @memberof SharedApiGetProblemSourceList
     */
    readonly level?: GetProblemSourceListLevelEnum

    /**
     * 
     * @type {boolean}
     * @memberof SharedApiGetProblemSourceList
     */
    readonly levelIsnull?: boolean

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiGetProblemSourceList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiGetProblemSourceList
     */
    readonly offset?: number

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SharedApiGetProblemSourceList
     */
    readonly ordering?: string

    /**
     * 
     * @type {number}
     * @memberof SharedApiGetProblemSourceList
     */
    readonly problemSourceSubject?: number

    /**
     * 
     * @type {'matura_exam' | 'textbook'}
     * @memberof SharedApiGetProblemSourceList
     */
    readonly type?: GetProblemSourceListTypeEnum
}

/**
 * Request parameters for getUserDataForm operation in SharedApi.
 * @export
 * @interface SharedApiGetUserDataFormRequest
 */
export interface SharedApiGetUserDataFormRequest {
    /**
     * A unique integer value identifying this user data form.
     * @type {number}
     * @memberof SharedApiGetUserDataForm
     */
    readonly id: number
}

/**
 * Request parameters for getUserDataFormAttempt operation in SharedApi.
 * @export
 * @interface SharedApiGetUserDataFormAttemptRequest
 */
export interface SharedApiGetUserDataFormAttemptRequest {
    /**
     * A unique integer value identifying this User onboarding attempt.
     * @type {number}
     * @memberof SharedApiGetUserDataFormAttempt
     */
    readonly id: number
}

/**
 * Request parameters for getUserDataFormAttemptList operation in SharedApi.
 * @export
 * @interface SharedApiGetUserDataFormAttemptListRequest
 */
export interface SharedApiGetUserDataFormAttemptListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiGetUserDataFormAttemptList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiGetUserDataFormAttemptList
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SharedApiGetUserDataFormAttemptList
     */
    readonly userDataForm?: number
}

/**
 * Request parameters for getUserDataFormList operation in SharedApi.
 * @export
 * @interface SharedApiGetUserDataFormListRequest
 */
export interface SharedApiGetUserDataFormListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiGetUserDataFormList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiGetUserDataFormList
     */
    readonly offset?: number
}

/**
 * Request parameters for getUserDataFormResponses operation in SharedApi.
 * @export
 * @interface SharedApiGetUserDataFormResponsesRequest
 */
export interface SharedApiGetUserDataFormResponsesRequest {
    /**
     * A unique integer value identifying this user data form.
     * @type {number}
     * @memberof SharedApiGetUserDataFormResponses
     */
    readonly id: number
}

/**
 * Request parameters for getUserDataFormStep operation in SharedApi.
 * @export
 * @interface SharedApiGetUserDataFormStepRequest
 */
export interface SharedApiGetUserDataFormStepRequest {
    /**
     * A unique integer value identifying this user data form step.
     * @type {number}
     * @memberof SharedApiGetUserDataFormStep
     */
    readonly id: number
}

/**
 * Request parameters for getVimeoFolders operation in SharedApi.
 * @export
 * @interface SharedApiGetVimeoFoldersRequest
 */
export interface SharedApiGetVimeoFoldersRequest {
    /**
     * 
     * @type {string}
     * @memberof SharedApiGetVimeoFolders
     */
    readonly fields?: string

    /**
     * 
     * @type {number}
     * @memberof SharedApiGetVimeoFolders
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SharedApiGetVimeoFolders
     */
    readonly perPage?: number
}

/**
 * Request parameters for getVimeoUploadLinkBatch operation in SharedApi.
 * @export
 * @interface SharedApiGetVimeoUploadLinkBatchRequest
 */
export interface SharedApiGetVimeoUploadLinkBatchRequest {
    /**
     * 
     * @type {Array<VimeoUploadLinkRequestRequest>}
     * @memberof SharedApiGetVimeoUploadLinkBatch
     */
    readonly vimeoUploadLinkRequestRequest: Array<VimeoUploadLinkRequestRequest>
}

/**
 * Request parameters for sendAccountActivationEmail operation in SharedApi.
 * @export
 * @interface SharedApiSendAccountActivationEmailRequest
 */
export interface SharedApiSendAccountActivationEmailRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof SharedApiSendAccountActivationEmail
     */
    readonly id: number
}

/**
 * Request parameters for sendConfirmationEmail operation in SharedApi.
 * @export
 * @interface SharedApiSendConfirmationEmailRequest
 */
export interface SharedApiSendConfirmationEmailRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof SharedApiSendConfirmationEmail
     */
    readonly id: number
}

/**
 * Request parameters for sharedApiProblemCreate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiProblemCreateRequest
 */
export interface SharedApiSharedApiProblemCreateRequest {
    /**
     * 
     * @type {ProblemRequest}
     * @memberof SharedApiSharedApiProblemCreate
     */
    readonly problemRequest: ProblemRequest
}

/**
 * Request parameters for sharedApiProblemDestroy operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiProblemDestroyRequest
 */
export interface SharedApiSharedApiProblemDestroyRequest {
    /**
     * A unique integer value identifying this problem.
     * @type {number}
     * @memberof SharedApiSharedApiProblemDestroy
     */
    readonly id: number
}

/**
 * Request parameters for sharedApiProblemSourceCreate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiProblemSourceCreateRequest
 */
export interface SharedApiSharedApiProblemSourceCreateRequest {
    /**
     * 
     * @type {ProblemSourceRequest}
     * @memberof SharedApiSharedApiProblemSourceCreate
     */
    readonly problemSourceRequest: ProblemSourceRequest
}

/**
 * Request parameters for sharedApiProblemSourceDestroy operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiProblemSourceDestroyRequest
 */
export interface SharedApiSharedApiProblemSourceDestroyRequest {
    /**
     * A unique integer value identifying this problem source.
     * @type {number}
     * @memberof SharedApiSharedApiProblemSourceDestroy
     */
    readonly id: number
}

/**
 * Request parameters for sharedApiProblemSourcePartialUpdate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiProblemSourcePartialUpdateRequest
 */
export interface SharedApiSharedApiProblemSourcePartialUpdateRequest {
    /**
     * A unique integer value identifying this problem source.
     * @type {number}
     * @memberof SharedApiSharedApiProblemSourcePartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedProblemSourceRequest}
     * @memberof SharedApiSharedApiProblemSourcePartialUpdate
     */
    readonly patchedProblemSourceRequest?: PatchedProblemSourceRequest
}

/**
 * Request parameters for sharedApiProblemSourceUpdate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiProblemSourceUpdateRequest
 */
export interface SharedApiSharedApiProblemSourceUpdateRequest {
    /**
     * A unique integer value identifying this problem source.
     * @type {number}
     * @memberof SharedApiSharedApiProblemSourceUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ProblemSourceRequest}
     * @memberof SharedApiSharedApiProblemSourceUpdate
     */
    readonly problemSourceRequest: ProblemSourceRequest
}

/**
 * Request parameters for sharedApiProblemUpdate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiProblemUpdateRequest
 */
export interface SharedApiSharedApiProblemUpdateRequest {
    /**
     * A unique integer value identifying this problem.
     * @type {number}
     * @memberof SharedApiSharedApiProblemUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ProblemRequest}
     * @memberof SharedApiSharedApiProblemUpdate
     */
    readonly problemRequest: ProblemRequest
}

/**
 * Request parameters for sharedApiUserDataFormAttemptCreate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserDataFormAttemptCreateRequest
 */
export interface SharedApiSharedApiUserDataFormAttemptCreateRequest {
    /**
     * 
     * @type {UserDataFormAttemptRequest}
     * @memberof SharedApiSharedApiUserDataFormAttemptCreate
     */
    readonly userDataFormAttemptRequest: UserDataFormAttemptRequest
}

/**
 * Request parameters for sharedApiUserDataFormAttemptDestroy operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserDataFormAttemptDestroyRequest
 */
export interface SharedApiSharedApiUserDataFormAttemptDestroyRequest {
    /**
     * A unique integer value identifying this User onboarding attempt.
     * @type {number}
     * @memberof SharedApiSharedApiUserDataFormAttemptDestroy
     */
    readonly id: number
}

/**
 * Request parameters for sharedApiUserDataFormAttemptPartialUpdate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserDataFormAttemptPartialUpdateRequest
 */
export interface SharedApiSharedApiUserDataFormAttemptPartialUpdateRequest {
    /**
     * A unique integer value identifying this User onboarding attempt.
     * @type {number}
     * @memberof SharedApiSharedApiUserDataFormAttemptPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedUserDataFormAttemptRequest}
     * @memberof SharedApiSharedApiUserDataFormAttemptPartialUpdate
     */
    readonly patchedUserDataFormAttemptRequest?: PatchedUserDataFormAttemptRequest
}

/**
 * Request parameters for sharedApiUserDataFormAttemptUpdate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserDataFormAttemptUpdateRequest
 */
export interface SharedApiSharedApiUserDataFormAttemptUpdateRequest {
    /**
     * A unique integer value identifying this User onboarding attempt.
     * @type {number}
     * @memberof SharedApiSharedApiUserDataFormAttemptUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UserDataFormAttemptRequest}
     * @memberof SharedApiSharedApiUserDataFormAttemptUpdate
     */
    readonly userDataFormAttemptRequest: UserDataFormAttemptRequest
}

/**
 * Request parameters for sharedApiUserDataFormScreenList operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserDataFormScreenListRequest
 */
export interface SharedApiSharedApiUserDataFormScreenListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiSharedApiUserDataFormScreenList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiSharedApiUserDataFormScreenList
     */
    readonly offset?: number
}

/**
 * Request parameters for sharedApiUserList operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserListRequest
 */
export interface SharedApiSharedApiUserListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiSharedApiUserList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiSharedApiUserList
     */
    readonly offset?: number
}

/**
 * Request parameters for sharedApiUserRetrieve operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserRetrieveRequest
 */
export interface SharedApiSharedApiUserRetrieveRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof SharedApiSharedApiUserRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for updateOrCreateUserDataFormAttempt operation in SharedApi.
 * @export
 * @interface SharedApiUpdateOrCreateUserDataFormAttemptRequest
 */
export interface SharedApiUpdateOrCreateUserDataFormAttemptRequest {
    /**
     * 
     * @type {UserDataFormAttemptRequest}
     * @memberof SharedApiUpdateOrCreateUserDataFormAttempt
     */
    readonly userDataFormAttemptRequest: UserDataFormAttemptRequest
}

/**
 * Request parameters for updateProblem operation in SharedApi.
 * @export
 * @interface SharedApiUpdateProblemRequest
 */
export interface SharedApiUpdateProblemRequest {
    /**
     * A unique integer value identifying this problem.
     * @type {number}
     * @memberof SharedApiUpdateProblem
     */
    readonly id: number

    /**
     * 
     * @type {PatchedProblemUpdateRequest}
     * @memberof SharedApiUpdateProblem
     */
    readonly patchedProblemUpdateRequest?: PatchedProblemUpdateRequest
}

/**
 * SharedApi - object-oriented interface
 * @export
 * @class SharedApi
 * @extends {BaseAPI}
 */
export class SharedApi extends BaseAPI {
    /**
     * 
     * @param {SharedApiGetCourseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getCourse(requestParameters: SharedApiGetCourseRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getCourse(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetCourseCourseModulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getCourseCourseModules(requestParameters: SharedApiGetCourseCourseModulesRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getCourseCourseModules(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetCourseDigitalTextbookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getCourseDigitalTextbook(requestParameters: SharedApiGetCourseDigitalTextbookRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getCourseDigitalTextbook(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetCourseListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getCourseList(requestParameters: SharedApiGetCourseListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getCourseList(requestParameters.digitalTextbook, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetCourseProblemSourceListingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getCourseProblemSourceListings(requestParameters: SharedApiGetCourseProblemSourceListingsRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getCourseProblemSourceListings(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetCourseVideoListingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getCourseVideoListings(requestParameters: SharedApiGetCourseVideoListingsRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getCourseVideoListings(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetDigitalTextbookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getDigitalTextbook(requestParameters: SharedApiGetDigitalTextbookRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getDigitalTextbook(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetDigitalTextbookListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getDigitalTextbookList(requestParameters: SharedApiGetDigitalTextbookListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getDigitalTextbookList(requestParameters.digitalTextbookSubject, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetDigitalTextbookListingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getDigitalTextbookListings(requestParameters: SharedApiGetDigitalTextbookListingsRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getDigitalTextbookListings(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetDigitalTextbookPrintPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getDigitalTextbookPrintPreview(requestParameters: SharedApiGetDigitalTextbookPrintPreviewRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getDigitalTextbookPrintPreview(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetDigitalTextbookSectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getDigitalTextbookSection(requestParameters: SharedApiGetDigitalTextbookSectionRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getDigitalTextbookSection(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetDigitalTextbookSectionListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getDigitalTextbookSectionList(requestParameters: SharedApiGetDigitalTextbookSectionListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getDigitalTextbookSectionList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetDigitalTextbookSectionPrintPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getDigitalTextbookSectionPrintPreview(requestParameters: SharedApiGetDigitalTextbookSectionPrintPreviewRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getDigitalTextbookSectionPrintPreview(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetDigitalTextbookSectionPublicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getDigitalTextbookSectionPublic(requestParameters: SharedApiGetDigitalTextbookSectionPublicRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getDigitalTextbookSectionPublic(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetDigitalTextbookWorkbookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getDigitalTextbookWorkbook(requestParameters: SharedApiGetDigitalTextbookWorkbookRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getDigitalTextbookWorkbook(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetDigitalTextbookWorkbookSectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getDigitalTextbookWorkbookSection(requestParameters: SharedApiGetDigitalTextbookWorkbookSectionRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getDigitalTextbookWorkbookSection(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetProblemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getProblem(requestParameters: SharedApiGetProblemRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getProblem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetProblemListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getProblemList(requestParameters: SharedApiGetProblemListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getProblemList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetProblemSourceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getProblemSource(requestParameters: SharedApiGetProblemSourceRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getProblemSource(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetProblemSourceListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getProblemSourceList(requestParameters: SharedApiGetProblemSourceListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getProblemSourceList(requestParameters.level, requestParameters.levelIsnull, requestParameters.limit, requestParameters.offset, requestParameters.ordering, requestParameters.problemSourceSubject, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetUserDataFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getUserDataForm(requestParameters: SharedApiGetUserDataFormRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getUserDataForm(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetUserDataFormAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getUserDataFormAttempt(requestParameters: SharedApiGetUserDataFormAttemptRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getUserDataFormAttempt(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetUserDataFormAttemptListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getUserDataFormAttemptList(requestParameters: SharedApiGetUserDataFormAttemptListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getUserDataFormAttemptList(requestParameters.limit, requestParameters.offset, requestParameters.userDataForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetUserDataFormListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getUserDataFormList(requestParameters: SharedApiGetUserDataFormListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getUserDataFormList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetUserDataFormResponsesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getUserDataFormResponses(requestParameters: SharedApiGetUserDataFormResponsesRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getUserDataFormResponses(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetUserDataFormStepRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getUserDataFormStep(requestParameters: SharedApiGetUserDataFormStepRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getUserDataFormStep(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetVimeoFoldersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getVimeoFolders(requestParameters: SharedApiGetVimeoFoldersRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getVimeoFolders(requestParameters.fields, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetVimeoUploadLinkBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getVimeoUploadLinkBatch(requestParameters: SharedApiGetVimeoUploadLinkBatchRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getVimeoUploadLinkBatch(requestParameters.vimeoUploadLinkRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send account activation email
     * @param {SharedApiSendAccountActivationEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sendAccountActivationEmail(requestParameters: SharedApiSendAccountActivationEmailRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sendAccountActivationEmail(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send confirmation email
     * @param {SharedApiSendConfirmationEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sendConfirmationEmail(requestParameters: SharedApiSendConfirmationEmailRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sendConfirmationEmail(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiCoursePreviewRetrieve(options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiCoursePreviewRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiProblemCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiProblemCreate(requestParameters: SharedApiSharedApiProblemCreateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiProblemCreate(requestParameters.problemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiProblemDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiProblemDestroy(requestParameters: SharedApiSharedApiProblemDestroyRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiProblemDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiProblemSourceCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiProblemSourceCreate(requestParameters: SharedApiSharedApiProblemSourceCreateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiProblemSourceCreate(requestParameters.problemSourceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiProblemSourceDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiProblemSourceDestroy(requestParameters: SharedApiSharedApiProblemSourceDestroyRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiProblemSourceDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiProblemSourcePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiProblemSourcePartialUpdate(requestParameters: SharedApiSharedApiProblemSourcePartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiProblemSourcePartialUpdate(requestParameters.id, requestParameters.patchedProblemSourceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiProblemSourceUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiProblemSourceUpdate(requestParameters: SharedApiSharedApiProblemSourceUpdateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiProblemSourceUpdate(requestParameters.id, requestParameters.problemSourceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiProblemUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiProblemUpdate(requestParameters: SharedApiSharedApiProblemUpdateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiProblemUpdate(requestParameters.id, requestParameters.problemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserDataFormAttemptCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserDataFormAttemptCreate(requestParameters: SharedApiSharedApiUserDataFormAttemptCreateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserDataFormAttemptCreate(requestParameters.userDataFormAttemptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserDataFormAttemptDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserDataFormAttemptDestroy(requestParameters: SharedApiSharedApiUserDataFormAttemptDestroyRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserDataFormAttemptDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserDataFormAttemptPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserDataFormAttemptPartialUpdate(requestParameters: SharedApiSharedApiUserDataFormAttemptPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserDataFormAttemptPartialUpdate(requestParameters.id, requestParameters.patchedUserDataFormAttemptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserDataFormAttemptUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserDataFormAttemptUpdate(requestParameters: SharedApiSharedApiUserDataFormAttemptUpdateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserDataFormAttemptUpdate(requestParameters.id, requestParameters.userDataFormAttemptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserDataFormScreenListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserDataFormScreenList(requestParameters: SharedApiSharedApiUserDataFormScreenListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserDataFormScreenList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserList(requestParameters: SharedApiSharedApiUserListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserRetrieve(requestParameters: SharedApiSharedApiUserRetrieveRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiVimeoCreateFolderCreate(options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiVimeoCreateFolderCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiUpdateOrCreateUserDataFormAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public updateOrCreateUserDataFormAttempt(requestParameters: SharedApiUpdateOrCreateUserDataFormAttemptRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).updateOrCreateUserDataFormAttempt(requestParameters.userDataFormAttemptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiUpdateProblemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public updateProblem(requestParameters: SharedApiUpdateProblemRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).updateProblem(requestParameters.id, requestParameters.patchedProblemUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetProblemSourceListLevelEnum = {
    A: 'A',
    B: 'B'
} as const;
export type GetProblemSourceListLevelEnum = typeof GetProblemSourceListLevelEnum[keyof typeof GetProblemSourceListLevelEnum];
/**
 * @export
 */
export const GetProblemSourceListTypeEnum = {
    MaturaExam: 'matura_exam',
    Textbook: 'textbook'
} as const;
export type GetProblemSourceListTypeEnum = typeof GetProblemSourceListTypeEnum[keyof typeof GetProblemSourceListTypeEnum];
